export enum ActionType {
  REQUEST_SIGN_IN = '[0] Request the login credentials to authenticate.',
  SIGN_IN_SUCCESS = '[1] Authentication has been success.',
  SET_USER_NEW_PASSWORD = '[1.1] Require new password.',
  SIGN_IN_FAIL = '[2] Authentication has been fail.',
  SET_USER_STATUS = '[3] Initialize user account status.',
  REQUEST_ACTION = '[4] Request action to API.',
  MESSAGE_ACTION = '[5] Current action message after completed an request.',
  GET_EVENTS_SUCCESS = '[6] Success getting list of inspections.',
  GET_PAGINATOR_SUCCESS = '[6] Success getting paginator.',
  GET_EVENTS_FAIL = '[7] Failed getting list of inspections.',
  SET_PLATE_FILTER = '[8] Change the inspection plate filter value.',
  CLEAR_PLATE_FILTER = '[9] Clean the inspection plate filter value.',
  SET_RECORD_FILTER = '[10] Change the record filter value.',
  CLEAR_RECORD_FILTER = '[11] Clean the record filter value.',
  GET_PRE_INSPECTIONS_SUCCESS = '[12] Success getting list of pre-inspections.',
  GET_PRE_INSPECTIONS_FAIL = '[13] Failed getting list of pre-inspections.',
  SET_PRE_INSPECTION_PLATE_FILTER = '[14] Change the pre-inspection plate filter value.',
  CLEAR_PRE_INSPECTION_PLATE_FILTER = '[15] Clean the pre-inspection plate filter value.',
  AUTOSAVE_PRE_INSPECTION = '[16] Auto save action to add or update pre-inspection.',
  SEND_PRE_INSPECTION = '[17] Sending the pre-inspection object to the API.',
  SET_PRE_INSPECTION_MODEL_TO_DEFAULT = '[18] Change the new pre-inspection model for default values.',
  SET_PRE_INSPECTION_IMAGES = '[19] Change the pre-inspection images in the current pre-inspection state.',
  SET_PRE_INSPECTION_IMAGES_WITH_SECTION = '[20] Change the pre-inspection images with section in the current pre-inspection state.',
  SET_PRE_INSPECTION_CAR_DAMAGES = '[21] Change the pre-inspection car damages in the current state.',
  SET_INSPECTION_MODEL_TO_DEFAULT = '[22] Change the new inspection model for default values.',
  SET_INSPECCION_IMAGES = '[23] Change the inspection images in the current inspection state.',
  SET_CURRENT_INSPECTION = '[24] Set the current inspection model state.',
  SET_GRID_REQUEST_PARAMS = '[25] Set the grid request parameters.',
  SET_LOADING = '[26] Set the loading flag to show loading screen.',
  SET_QUERY_FILTERS = '[27] Set query filters.',
  SET_EXPRESS_ADJUST = '[28] Set IndExpressAdjust from currentInspection.',
  SET_INSPECTION_THIRDS = '[29] Set currentInspection thirds array.',
  SET_INSPECTION_INJURY_PERSONS = '[30] Set currentInspection injury persons array.',
  SET_INSPECTION_COVERAGES = '[31] Set currentInspection coverages array.',
  INIT_STATE = '[32] Set state to its initial config.',
  SET_GENERAL_ALERT = '[33] Set state to add or clean a general alert message.',
  SET_CURRENT_FORM_STATUS = '[34] Set state for the current inspection form.',
  SET_CURRENT_USER = '[35] Set state for the current logged user.',
  SET_CONSEQUENCES = '[36] Set the consequences data.',
  SET_CONFIRM_AAP_FORM = '[37] Set the flag if the AAP form is complete.',
  SET_OPTIONS_AAP_FORM = '[38] Set the dropdown option and the option called other text if the AAP form is not use.',
  SET_AAP_FLAG = '[39] Set the AAP option in the current state.',
  SET_VEHICLE_DEFINITION = '[40] Set the vehicle definition value in the current state.',
  SET_SKETCH_DATA = '[41] Set data related to sketch component.',
  SET_NOT_COVERAGE = '[42] Set data for NO Coverage.',
  SET_REASON_ID = '[43] Set data for ReasonId.',
  SET_EVENT_STATUS = '[44] Set status data for current event.',
  SET_IND_PART_ACC = '[45] Set value for the field called "¿Hay Parte?".',
  SET_FLAG_REQUIRED_CUSTOM_PHOTOS = '[46] Set flag value for validate custom photos in the finalize action.',
  SET_REQUIRED_QUALIEXPRESS = '[46] Set data for QualiExpress.',
  SET_REQUIRED_DIAGRAM = '[47] Validate data for diagram.',
  SET_ACCIDENT_PLACE = '[48] Set the accident place.',
  SET_REQUIRED_SINISTERTYPE = '[49] Validate data for sinister type.',
  SET_REQUIRED_SINISTERCAUSE = '[50] Validate data for sinieter cause.',
  GET_PREINSPECTS_SUCCESS = '[51] Success getting list of pre-inspections.',
  SET_APP_FORM_TYPE = '[52] Change de app type for inspection or pre-inspection.',
  SET_PREINSPECTS_MODEL = '[53] Set the preinspection model for update the current info.',
  SET_PREINSPECTS_FORM = '[54] Set the preinspection form model for add or update info.',
  SET_PREINSPECTS_MEDIAFILES = '[55] Set the preinspection media files for render.',
  SET_PREINSPECTS_UNIQUEID = '[56] Set preinspection unique id.',
  SET_USER_INSPECTOR = '[57] Inspector user name',
  SET_SINISTER_DATE = '[58] Set sinister date.',
  SET_REQUIRED_ACCEPT_SIGNATURE = '[59] Validate data for accept signtature.',
  SET_RESTORE_NET_CONNECTION = '[60] Validate if the user restore the net connection.',
  SET_NET_CONNECTION = '[61] Validate if the user has net connection.',
  SET_AUDIO_STORY = '[62] Set inspection state audio',
  SET_AUDIO_PRESIGNED = '[63] Set inspection state audio presigned',
  SET_AUDIO_TRANSCRIPT = '[64] Set audio transcript',
  SET_ALERT_REQUEST_PASSWORD = '[65] Send message when the change password is requested.',
  SET_ALERT_NEW_USER = '[66] Send message when create a new user.',
  SET_ALERT_CHANGE_PASSWORD = '[69] Send message when change password.',
  SET_LIST_FOR_ROTATE_IMAGES = '[70] Set rotate images for request a trigger.',
  SET_AUDIT_EVENT_STATUS = '[71] Set audit status for current event.',
  SET_NOT_AUDIT_EVENT = '[72] Set not audit event for current event.',
  SET_AUDIT_EVENT_MSG = '[73] Set audit message for current event.',
  SET_AUDIT_EVENT_TIMES = '[74] Set times for audit event process in current event.',
  SET_AUDIT_SECTION = '[75] Set section ID for audit event process in current event.',
  SET_AUDIT_PROCESS_SECTION = '[76] Set in the section list the added section who has been processed.',
  SET_AUDIO_INSURED = '[77] Set audio insured',
  SET_AUDIO_INSURED_PRESIGNED = '[78] Set audio insured presigned',
  SET_AUDIO_INJURED = '[78] Set audio injured',
  SET_AUDIO_INJURED_PRESIGNED = '[78] Set audio injured presigned',
  SET_AUDIO_TRANSCRIPT_INSURED = '[79] Set audio transcript insured',
  SET_AUDIO_TRANSCRIPT_INJURED = '[80] Set audio transcript injured',
  SET_AUDIT_TRIGGER_THIRD_SECTION = '[81] Trigger action for save the third section data',
  SET_AUDIT_TRIGGER_INJURED_SECTION = '[82] Trigger action for save the injured section data',
  SET_BILLRIGHTS_MAIL = '[83] SET EMAIL FOR BILL OF RIGHTS',
  SET_INTEGRATION_RESEND_ID_EVENT = '[83] Set the event id to resend in the integration',
  SET_INTEGRATION_CONFIRM_RESEND = '[84] Set the variable to enable the confirm message',
  SET_IND_AIRBAG = '[85] Set indicator value',
  SET_IND_SEATBELT = '[86] Set indicator value',
  SET_IND_FRONTAXLE = '[87] Set indicator value',
  SET_IND_REARAXLE = '[88] Set indicator value',
  SET_IND_LOOSEPARTS = '[89] Set indicator value',
  SET_IND_OPENTRUNK = '[90] Set indicator value',
  SET_IND_SINIESTER_TYPE = '[91] Set indicator value',
  SET_IND_AIRBAG_INSURED = '[92] Set indicator value',
  SET_IND_SEATBELT_INSURED = '[93] Set indicator value',
  SET_IND_FRONTAXLE_INSURED = '[94] Set indicator value',
  SET_IND_REARAXLE_INSURED = '[95] Set indicator value',
  SET_IND_LOOSEPARTS_INSURED = '[96] Set indicator value',
  SET_IND_OPENTRUNK_INSURED = '[97] Set indicator value',
  SET_IND_SINIESTER_TYPE_INSURED = '[98] Set indicator value',
  SET_IS_VALID_CUSTOM_PHOTOS = '[99] Set if the custom photos is valid and have not required photos',
  SET_AUDIT_TRIGGER_PHOTO_SECTION = '[100] Trigger action for save the photo section data',
  SET_DRIVER_NAME = '[101] Set driver name',
  SET_DRIVER_LAST_NAME = '[102] Set driver last name',
  SET_DRIVER_ID = '[103] Set driver id',
  SET_DRIVER_LICENSE_NUMBER = '[104] Set driver license number',
  SET_DRIVER_LICENSE_EXPIRATION = '[105] Set driver license expiration date',
  SET_DRIVER_BIRTH_DATE = '[106] Set driver birth date',
  SET_DRIVER_LICENSE_TYPE = '[107] Set driver license type',
  SET_CLAIMS_TRACKER_DATA = '[108] Set claims tracker data',
  SET_REFRESH_AAP_IFRAME = '[109] Set refresh for AAP Iframe',
  SET_EXPENSES_AMOUNT = '[110] Set expense amount',
  SET_ADJUST_AMOUNT = '[111] Set adjust amount',
  SET_ESTIMATED_AMOUNT = '[112] Set estimated amount',
  SET_CLAIMS_INFO_DATA = '[113] Set claims management tool info data',
  SET_WORKSHOPS_DATA = '[114] Set workshops info data',
  SET_RETRIES_PROCESS_FLAG = '[115] Set the flag for the retries process',
  SET_ASSISTANCE_REQUIRED = '[116] Set the flag for user require legal assistance',
  SET_PLATE_CIRCUNSTANCES_INSURED = '[117] Set plate circunstances',
  SET_DEPRECIATIONS = '[118] Set depreciations',
  SET_SHOW_DIAGRAM = '[119] Set show diagram',
  SET_BRAND = '[120] Set vehicle brand',
  SET_MODEL = '[121] Set vehicle model',
  SET_YEAR = '[122] Set vehicle year',
  SET_COLOR = '[123] Set vehicle color',
  SET_PLATE = '[124] Set vehicle plate',
  SET_VEHICLE_VIN = '[125] Set vehicle VIN',
  SET_VEHICLE_ENGINE = '[126] Set vehicle Engine',
  SET_PHOTO_ALERT = '[127] Set state to add or clean a photo alert message.',
  SET_UPLOAD_PROGRESS = '[128] Set state to upload progress.',
  SET_ERROR_MODAL = '[129] Error upload images',
  SET_COUNT_PROGREES_UPLOAD_IMAGES = '[130] Set total upload progress',
  SET_PARTS_AMOUNT = '[131] Set part amount',
  SET_MFA_CHALLENGE = '[132] Set MFA challenge data',
}

export interface IAction {
  type: ActionType;
  payload?: any;
}

export type DispatchFunction = (event: IAction) => void;
