import { IUserContext, UserContext } from 'context/user';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface GuardRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any; // Para poder pasar otras props como `exact`, `path`, etc.
}

const GuardRoute: React.FC<GuardRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { state } = useContext<IUserContext>(
    UserContext,
  );
  const accessToken = localStorage.getItem('accessToken');
  const isAuthenticated = accessToken != undefined || (state.currentUserStatus?.isOneAccess && state.currentUserStatus?.isLogin);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
            <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default GuardRoute;
