import SideMenu from 'components/layout/side-menu';
import TopBar from 'components/layout/top-bar';
import Footer from 'components/layout/footer';
import {
  getEventGridColumns,
  IInspectionContext,
  InspectionContext,
} from 'context/inspections';
import {
  IUserContext,
  UserContext,
  getCurrentCognitoUser,
  getCurrentSession,
  getUserInitialScreen,
  requestSignOut,
  updateCurrentCountryUser,
  verifyOneAccessToken,
} from 'context/user';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { OPTIONS_TABS, TABS } from 'static/constants/menu-tabs';
import PATH_ROUTES from 'static/constants/path-routes';
import { ActionType } from 'types/action';
import { Tab } from 'types/tab';
import './styles.scss';
import {
  IFiltersContext,
  FiltersContext,
} from 'context/filters/FiltersContext';
import {
  IPreInspectionContext,
  PreInspectionContext,
} from 'context/preinspections';
import { Snackbar } from '@mui/material';
import { Alert, AlertColor } from '@mui/lab';
import { userActionsPermissions } from 'shared/utils';
import {
  BrandsId,
  CmtCountries,
  CountryCode,
  FormType,
  ResourceAction,
  Rol,
} from 'types/common';
import { IAppFormType } from 'types/user';
import useDevice from 'shared/hooks/useDevice';
import FooterContext from 'context/footer/footer-context';
import HeaderContext from 'context/header/header-context';
import Loader from 'components/common/loader/Loader';
import { StyleContext } from 'context/style/style-context';

interface Props {
  children?: JSX.Element;
}

const Layout = (props: Props): JSX.Element => {
  //Context
  const { state, dispatch: userDispatch } = useContext<IUserContext>(
    UserContext,
  );
  const { dispatch: inspectDispatch, state: inspectContext } = useContext<
    IInspectionContext
  >(InspectionContext);
  const { dispatch: preInspectDispatch } = useContext<IPreInspectionContext>(
    PreInspectionContext,
  );
  const history = useHistory();
  const location = useLocation();

  //states
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab | null | undefined>(null);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarPhoto, setShowSnackbarPhoto] = useState<boolean>(false);
  const [typeMessage, setTypeMessage] = useState<AlertColor | undefined>(
    'success',
  );
  const [typeMessagePhoto, setTypeMessagePhoto] = useState<
    AlertColor | undefined
  >('success');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertMessagePhoto, setAlertMessagePhoto] = useState<string>('');
  const [propFromChildren, setPropFromChildren] = useState('');
  const [propContentTitle, setPropContentTitle] = useState(
    'Dashboard Reclamaciones',
  );

  const { queryFilters, searchQuery, setQueryFilters } = useContext<
    IFiltersContext
  >(FiltersContext);

  const [showLayout, setShowLayout] = useState(false);
  const [showLayoutGrid, setShowLayoutGrid] = useState(true);
  const { pathname, search } = useLocation();
  const [manualLogout, setManualLogout] = useState(false);

  //Filters
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [plate, setPlate] = useState(queryFilters.plate || '');
  const [record, setRecord] = useState(queryFilters.filterText || '');
  const [typeRecord, setTypeRecord] = useState(queryFilters.filterType || '');
  const menuRef = React.createRef<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const { paddingTop } = useContext(StyleContext);

  // Handle mobile view
  const breakpoints = useDevice();

  const userModel = state.currentUserStatus;

  const closeUserSession = useCallback(() => {
    setLoading(true);
    (async (): Promise<void> => {
      localStorage.clear();
      await requestSignOut();

      userDispatch({ type: ActionType.INIT_STATE });
      inspectDispatch({ type: ActionType.INIT_STATE });
    })();

    const dbName = 'photoDatabase';
    const request = indexedDB.open(dbName);

    // Manejar el evento de éxito de la apertura de la conexión
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      // Cerrar la conexión para asegurar que no haya transacciones pendientes
      db.close();
      // Eliminar la base de datos
      const deleteRequest = indexedDB.deleteDatabase(dbName);
      // Manejar el evento de éxito del borrado
      deleteRequest.onsuccess = () => {
        console.warn('Base de datos eliminada con éxito.');
      };
      // Manejar el evento de error del borrado
      deleteRequest.onerror = (event) => {
        console.error(
          'Error al eliminar la base de datos:',
          (event.target as IDBRequest).error,
        );
      };
    };
    // Manejar el evento de error de la apertura de la conexión
    request.onerror = (event) => {
      console.error(
        'Error al abrir la conexión:',
        (event.target as IDBRequest).error,
      );
    };
  }, [userDispatch, userModel, inspectDispatch]);

  const validateUserSession = useCallback(async (): Promise<void> => {
    const { isAuthenticate, sessionExpired } = await getCurrentSession(
      userModel,
      userDispatch,
    );

    if (manualLogout) {
      history.push('/');
    }

    if (
      sessionExpired &&
      !location.pathname.includes(PATH_ROUTES.ONE_ACCESS) &&
      !userModel.isOneAccess
    ) {
      if (isAuthenticate) {
        closeUserSession();
      }
      if (!manualLogout && !userModel.isLogin && !userModel.token) {
        history.push(`/?redirectTo=${encodeURI(location.pathname)}`);
      }
    }

    // Validate country and role for get Columns
    getGridColumnsConfig();
  }, [
    userDispatch,
    history,
    userModel,
    closeUserSession,
    location.pathname,
    manualLogout,
  ]);

  useEffect(() => {
    if (
      pathname.includes('/inspections/') &&
      pathname.includes('/sections/') &&
      queryFilters.inspectorForm
    ) {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  }, [pathname, queryFilters]);

  useEffect(() => {
    setShowSnackbar(false);
    if (
      inspectContext.showAlert &&
      inspectContext.alertMessage &&
      inspectContext.alertType &&
      String(inspectContext.alertMessage).length > 0
    ) {
      setShowSnackbar(true);
      setTypeMessage(inspectContext.alertType as AlertColor);
      setAlertMessage(inspectContext.alertMessage);
    }
  }, [
    inspectContext.showAlert,
    inspectContext.alertMessage,
    inspectContext.alertType,
  ]);

  useEffect(() => {
    if (showSnackbarPhoto && typeMessagePhoto === 'success') {
      const timer = setTimeout(() => {
        handleSnackBarClearMessagePhoto();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showSnackbarPhoto, typeMessagePhoto]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (inspectContext.inProgress) {
        const message =
          'Estás intentando salir de la página mientras se están subiendo imágenes. ¿Estás seguro?';
        event.returnValue = message;

        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [inspectContext.inProgress]);

  useEffect(() => {
    const isOneAccessPath = location.pathname.includes(PATH_ROUTES.ONE_ACCESS);

    (async () => {
      if (userModel.isOneAccess || isOneAccessPath) {
        const oneAccessToken = new URLSearchParams(search).get('token');
        if (oneAccessToken) {
          localStorage.setItem('oneAccessToken', oneAccessToken);
          userDispatch({ type: ActionType.SET_LOADING, payload: true });
        }

        const response = await verifyOneAccessToken(
          oneAccessToken ?? localStorage.getItem('oneAccessToken') ?? '',
        );

        if (!response.data?.success) {
          localStorage.clear();
          userDispatch({ type: ActionType.INIT_STATE });
          history.push('/');

          return;
        }

        if (
          (userModel.isOneAccess && isOneAccessPath) ||
          (!userModel.isLogin && isOneAccessPath) ||
          (userModel.token && isOneAccessPath)
        ) {
          await validateUserSession();
          history.push(getUserInitialScreen());
        }
      }
    })();

    // User Session
    if (
      !userModel.role &&
      location.pathname !== '/' &&
      !userModel.isOneAccess &&
      !isOneAccessPath
    ) {
      validateUserSession();
    } else if (!pathname.includes('/in-progress')) {
      const inspectColumns = localStorage.getItem('gridColumns');
      const preInspectColumns = localStorage.getItem(
        'gridColumnsPreInspection',
      );
      if (!inspectColumns || !preInspectColumns) {
        setShowLayoutGrid(false);
      }
    }

    if (!activeTab) {
      const currentPath = location.pathname;
      let currentPathTab: Tab | undefined = undefined;
      TABS.every((tab) => {
        if (tab.route && currentPath.includes(tab.route)) {
          currentPathTab = tab;

          return false;
        }
        if (tab.subtabs) {
          currentPathTab = tab.subtabs.find((subtab) =>
            subtab.route ? currentPath.includes(subtab.route) : null,
          );

          return currentPathTab ? false : true;
        }

        return true;
      });
      setActiveTab(currentPathTab);
    }
  }, [pathname, activeTab, location.pathname, userModel, validateUserSession]);

  useEffect(() => {
    getMenuByPermissions(TABS);
    getMenuByPermissions(OPTIONS_TABS);
  }, []);

  useEffect(() => {
    if (!inspectContext.loading && inspectContext.inspections) {
      setLoading(false);
    }
  }, [inspectContext.inspections]);

  const handleSnackBarClearMessage = (): void => {
    setShowSnackbar(false);
    inspectDispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: false,
        alertMessage: '',
        alertType: '',
      },
    });
  };

  const handleSnackBarClearMessagePhoto = (): void => {
    setShowSnackbarPhoto(false);
    inspectDispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: false,
        alertMessagePhoto: '',
        alertTypePhoto: '',
      },
    });
  };

  const handleClickedSearch = async () => {
    const containsPreInspection = location.pathname.includes('/preinspections');
    if (typeRecord && record) {
      if (containsPreInspection) {
        preInspectDispatch({
          type: ActionType.SET_RECORD_FILTER,
          payload: plate,
        });
      } else {
        inspectDispatch({
          type: ActionType.SET_RECORD_FILTER,
          payload: record,
        });
      }
    } else {
      if (containsPreInspection) {
        preInspectDispatch({
          type: ActionType.CLEAR_PLATE_FILTER,
        });
      }
      inspectDispatch({ type: ActionType.CLEAR_RECORD_FILTER });
      inspectDispatch({ type: ActionType.CLEAR_PLATE_FILTER });
    }

    const countryCode = localStorage.getItem('countryCode') ?? '';

    if (!record || String(record).length === 0) {
      setQueryFilters({ filterType: undefined, filterText: undefined });
    } else {
      setQueryFilters({
        filterType: typeRecord,
        filterText: record,
        inspectorForm: undefined,
        countryCode: countryCode.toUpperCase(),
        page: undefined,
      });
    }
  };

  const handleClear = () => {
    inspectDispatch({ type: ActionType.CLEAR_RECORD_FILTER });
    setRecord('');
    setTypeRecord('');
    setQueryFilters({ filterType: undefined, filterText: undefined });
  };

  const handleTabClicked = (tab: Tab) => {
    setActiveTab(tab);

    if (tab.value === 'logout') {
      setManualLogout(true);
      closeUserSession();
    } else {
      if (!tab.route || tab.route === PATH_ROUTES.EVENTS) {
        setQueryFilters({ filterType: undefined, filterText: undefined });
      }

      if (
        CmtCountries.includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
      ) {
        history.push(tab.route ? tab.route : PATH_ROUTES.EVENTS_CLAIMS);
      } else {
        history.push(tab.route ? tab.route : PATH_ROUTES.EVENTS);
      }
    }
  };

  const handleRecordChange = (type: string, value: string) => {
    if (type === 'FULL_NAME') {
      setRecord(value);
    } else {
      const dataWithOutSpaces = value.replace(/\s/g, '');
      setRecord(dataWithOutSpaces);
    }

    setTypeRecord(type);
  };

  if (searchQuery && !queryFilters.inspectorForm) {
    if (state.appFormType === IAppFormType.INSPECTION) {
      if (
        CmtCountries.includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
      ) {
        return (
          <Redirect to={{ pathname: '/events-claims', search: searchQuery }} />
        );
      } else {
        return <Redirect to={{ pathname: '/events', search: searchQuery }} />;
      }
    } else if (state.appFormType === IAppFormType.PRE_INSPECTION) {
      return (
        <Redirect to={{ pathname: '/preinspections', search: searchQuery }} />
      );
    }
  }

  const getMenuByPermissions = (options: Tab[]): Tab[] => {
    const roleId = localStorage.getItem('rolId');
    const isMultiCountry = localStorage.getItem('isMultiCountry');
    const reportMenuPermission = userActionsPermissions(
      ResourceAction.MENU_ACCESS_REPORT,
    );
    const userMenuPermission = userActionsPermissions(
      ResourceAction.MENU_ADMIN_USERS,
    );
    const inspectionMenuPermission = userActionsPermissions(
      ResourceAction.MENU_ACCESS_INSPECTIONS,
    );
    const preInspectionMenuPermission = userActionsPermissions(
      ResourceAction.MENU_ACCESS_PREINSPECTIONS,
    );
    const createPreInspectionMenuPermission = userActionsPermissions(
      ResourceAction.MENU_CREATE_PREINSPECTIONS,
    );
    const preInspectionDamageMenuPermission = userActionsPermissions(
      ResourceAction.MENU_DAMAGE_PARAMETERS,
    );

    return options.map((m) => {
      if (m.subtabs) {
        m.subtabs.forEach((st) => {
          if (st.value === 'report') {
            st.isActive = Boolean(reportMenuPermission);
            if (
              Number(roleId) === Rol.CUSTOMER &&
              Number(localStorage.getItem('companyId')) === BrandsId.QUALITAS
            ) {
              st.isActive = true;
            }
            if (
              isMultiCountry &&
              [Rol.BROKER, Rol.PRE_BROKER].includes(Number(roleId))
            ) {
              st.isActive = false;
            }
          }
          if (st.value === 'createPreinspection') {
            st.isActive = Boolean(createPreInspectionMenuPermission);
          }
          if (st.value === 'configDamages') {
            st.isActive = Boolean(preInspectionDamageMenuPermission);
          }

          return true;
        });
      }

      if (m.value === 'settings') {
        m.isActive = Boolean(userMenuPermission);
      } else if (m.value === 'inspection') {
        m.isActive = Boolean(inspectionMenuPermission);
      } else if (m.value === 'preinspection') {
        m.isActive = Boolean(preInspectionMenuPermission);
      }

      return m;
    });
  };

  const getGridColumnsConfig = async (): Promise<void> => {
    const companyId = localStorage.getItem('companyId') ?? '0';
    const roleId = localStorage.getItem('rolId') ?? '0';
    const countryCode = localStorage.getItem('countryCode') as CountryCode;

    if (Number(companyId) > 0) {
      const columnsInspection = await getEventGridColumns(
        Number(companyId),
        Number(roleId),
        CmtCountries.includes(countryCode)
          ? FormType.CLAIM_MANAGEMENT
          : FormType.INSPECTION,
      );

      localStorage.setItem(
        'gridColumns',
        JSON.stringify(columnsInspection.response),
      );

      const columnsPreInspection = await getEventGridColumns(
        Number(companyId),
        Number(roleId),
        FormType.PRE_INSPECTION,
      );
      localStorage.setItem(
        'gridColumnsPreInspection',
        JSON.stringify(columnsPreInspection.response),
      );

      // Show the grid after the columns configuration is finish
      setShowLayoutGrid(true);
    }
  };

  const handleOpenDrawer = (value: boolean) => {
    setOpen(value);
  };

  const handleCountryChange = async (countryCode: string) => {
    setLoading(true);
    await updateCurrentCountryUser(countryCode);
    await validateUserSession();
    localStorage.removeItem('agents');
    setQueryFilters({
      companyId: undefined,
      statusId: undefined,
      logAgentId: undefined,
    });
    history.push(getUserInitialScreen());
  };

  return (
    <div className="layout">
      <Loader isActive={loading} />
      <HeaderContext.Provider value={{ propContentTitle, setPropContentTitle }}>
        {showLayout && (
          <TopBar
            isDrawerOpen={open}
            searchText={record}
            searchType={typeRecord}
            onClear={handleClear}
            onClickSearch={handleClickedSearch}
            onChangeRecord={(type, value) => handleRecordChange(type, value)}
            breakpoints={breakpoints}
            onpenDrawer={handleOpenDrawer}
            onCountryChange={handleCountryChange}
          />
        )}
        {showLayout && TABS.length > 0 && (
          <SideMenu
            isOpen={open}
            tabs={getMenuByPermissions(TABS)}
            optionsTabs={getMenuByPermissions(OPTIONS_TABS)}
            activeTab={activeTab}
            menuRef={menuRef}
            onDrawerOpen={() => handleOpenDrawer(true)}
            onDrawerClose={() => handleOpenDrawer(false)}
            onTabClicked={handleTabClicked}
            breakpoints={breakpoints}
            onpenDrawer={handleOpenDrawer}
            hideOptions={new URLSearchParams(search).get('force') === '1'}
          />
        )}
        <FooterContext.Provider
          value={{ propFromChildren, setPropFromChildren }}
        >
          <div
            className="layout__children"
            id="app-container"
            style={{ paddingTop }}
          >
            {showLayoutGrid && props.children}
          </div>
          {showLayout && <Footer isDrawerOpen={open} />}
        </FooterContext.Provider>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showSnackbar}
        >
          <Alert
            onClose={() => handleSnackBarClearMessage()}
            severity={typeMessage}
          >
            <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showSnackbarPhoto}
        >
          {typeMessagePhoto !== 'success' ? (
            <Alert severity={typeMessagePhoto}>
              <div dangerouslySetInnerHTML={{ __html: alertMessagePhoto }} />
            </Alert>
          ) : (
            <Alert
              onClose={() => handleSnackBarClearMessagePhoto()}
              severity={typeMessagePhoto}
            >
              <div dangerouslySetInnerHTML={{ __html: alertMessagePhoto }} />
            </Alert>
          )}
        </Snackbar>
      </HeaderContext.Provider>
    </div>
  );
};

export default Layout;
