import clsx from 'clsx';
import ValidationLabel from 'components/common/validation-label';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { IUserModel } from 'types/user';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import Loader from 'components/common/loader/Loader';
import { patternEmail } from 'components/inspection/section/utils';
import { Rol } from 'types/common';
import { requestChangePassword, IUserContext, UserContext } from 'context/user';
import { ActionType, DispatchFunction } from 'types/action';
import { SettingsTab } from 'static/constants/settings-tabs';
import InputMask from 'react-input-mask';
import { phoneMasks } from 'types/common';

interface Props {
  labelForSubmit: string;
  isNewUser: boolean;
  currentUser: IUserModel | null;
  currentUserCompany: number | null;
  currentUserRole: number | null;
  currentUserActive: boolean;
  currentRunnerOwner?: string | undefined;
  listRoles: any[];
  listCompanies: any[];
  listRunnerOwners: any[];
  enableChangePassword: boolean;
  onShowForm: (value: boolean) => void;
  onActiveFetch: (value: boolean) => void;
  onCreateOrUpdate: (
    user: IUserModel,
    dispatch: DispatchFunction,
  ) => Promise<void>;
  listCountries?: any[];
  isUserTab?: boolean;
  currentTab: SettingsTab;
}

const UserForm = (props: Props): JSX.Element => {
  const { handleSubmit, register, errors } = useForm();
  const { state: userState, dispatch } = useContext<IUserContext>(UserContext);
  /* User info */
  const defaultUserData: IUserModel = {
    name: '',
    email: '',
    rolId: 0,
    companyId: 0,
    guidID: '',
    active: true,
    isMultiCountry: false,
    updatedBy: Number(localStorage.getItem('userId')),
  };
  const currentUser = props.currentUser ?? defaultUserData;
  const isNewUser = props.isNewUser;
  const [companyUser, setCompanyUser] = useState<string[] | string | null>(
    currentUser.companyIdList
      ? currentUser.companyIdList.split(',')
      : currentUser.companyId
      ? String(currentUser.companyId)
      : null,
  );
  const [roleUser, setRoleUser] = useState<number | null>(
    props.currentUserRole,
  );
  const [runnerCode, setRunnerCode] = useState<string | undefined>(
    props.currentRunnerOwner,
  );
  const [activeUser, setActiveUser] = useState(props.currentUserActive);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [customerTags, setCustomerTags] = useState<string[]>([]);
  const [firstRender, setFirstRender] = useState(true);
  const [countryUser, setCountryUser] = useState<string[] | string>(
    currentUser.countryIdList
      ? currentUser.countryIdList.split(',')
      : currentUser.codPais
      ? currentUser.codPais
      : [],
  );
  const [multiCountry, setMultiCountry] = useState(
    currentUser.isMultiCountry
      ? currentUser.isMultiCountry && SettingsTab.USERS === props.currentTab
      : false,
  );
  const [multiCompany, setMultiCompany] = useState(currentUser.isMultiCountry);
  const [userCountryCode, setUserCountryCode] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState(
    currentUser.cellphone,
  );
  const [use2FA, setUse2FA] = useState(currentUser.use2FA);
  const [useSSO, setUseSSO] = useState(currentUser.useSSO);

  const inputRefCellPhoneField = useRef<any>(null);

  const showMultiCountryButton =
    isNewUser ||
    (!isNewUser &&
      (!multiCountry || !currentUser.isMultiCountry) &&
      roleUser !== null &&
      [Rol.ADMIN, Rol.ADJUSTER, Rol.AUDIT].includes(roleUser));

  const useSelectedCountry =
    multiCountry || props.currentTab === SettingsTab.COMPANIES;

  const filteredRoleList = useMemo(
    () =>
      props.listRoles.filter((item) => {
        if (multiCountry) {
          return (
            item.id === Rol.ADMIN ||
            item.id === Rol.ADJUSTER ||
            item.id === Rol.AUDIT
          );
        }

        if (props.currentTab === SettingsTab.USERS && !multiCountry) {
          return item.id !== Rol.ADMIN;
        }

        if (props.currentTab !== SettingsTab.USERS) {
          return true;
        }
      }),
    [multiCountry, props.listRoles],
  );

  const filteredBrokerList = useMemo(() => {
    if (!multiCompany || !companyUser) {
      return [];
    }

    return props.listRunnerOwners.filter((broker) =>
      countryUser.includes(broker.countryCode),
    );
  }, [multiCompany, companyUser, props.listRunnerOwners]);

  const closeAlertRequest = () => {
    dispatch({
      type: ActionType.SET_ALERT_REQUEST_PASSWORD,
      payload: {
        show: false,
        isError: false,
      },
    });
    setChangePassword(false);
  };

  const closeAlertNewUser = () => {
    const hasError = userState.isErrorCreateUsrAlert;
    dispatch({
      type: ActionType.SET_ALERT_NEW_USER,
      payload: {
        show: false,
        isError: false,
      },
    });

    if (loading && !hasError) {
      setTimeout(() => {
        props.onShowForm(false);
        props.onActiveFetch(true);
        setLoading(false);
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  const requestChangePW = () => {
    (async (): Promise<void> => {
      await requestChangePassword(currentUser.email, dispatch);
    })();
  };

  const handleTagChange = (tags: string[]): void => {
    setCustomerTags(tags);
  };

  const onSubmit = useCallback(
    (data: any) => {
      setLoading(true);
      (async (): Promise<void> => {
        let dataUser: IUserModel = {
          ...defaultUserData,
          active: true,
          guidID: uuidv4(),
        };

        if (!isNewUser) {
          dataUser = { ...currentUser, active: activeUser };
        }

        if (!companyUser) {
          setCompanyUser(null);
        }

        if (!roleUser) {
          setRoleUser(0);
        }

        if (companyUser && roleUser && userCountryCode) {
          setLoading(true);

          dataUser = {
            ...dataUser,
            name: data.username + ' ' + data.lastname,
            email: data.email,
            companyId: Number(companyUser) || 0,
            rolId: roleUser,
            runnerCode: runnerCode,
            vendorID:
              roleUser === Rol.INSPECTOR ? data.vendorID : currentUser.vendorID,
            customerCode:
              roleUser === Rol.CUSTOMER
                ? customerTags.join('|')
                : currentUser.customerCode,
            isNewUser: isNewUser,
            isMultiCountry: multiCountry || multiCompany,
            companyIdList:
              multiCountry || multiCompany
                ? Array.isArray(companyUser)
                  ? companyUser.join(',')
                  : companyUser
                : undefined,
            countryIdList:
              multiCountry || multiCompany
                ? Array.isArray(countryUser)
                  ? countryUser.join(',')
                  : countryUser
                : undefined,
            cellphone: contactPhoneNumber,
            use2FA: use2FA,
            useSSO: useSSO,
          };

          await props.onCreateOrUpdate(dataUser, dispatch);
        } else {
          setLoading(false);
        }
      })();
    },
    [
      companyUser,
      roleUser,
      runnerCode,
      defaultUserData,
      changePassword,
      activeUser,
      currentUser,
      isNewUser,
      newPassword,
      props,
      dispatch,
      customerTags,
    ],
  );

  const filterCompanies = (company: any) => {
    if (multiCountry || props.currentTab === SettingsTab.COMPANIES) {
      return countryUser.includes(company.code);
    }
    if (props.currentTab === SettingsTab.USERS) {
      return company.type === 'INTERNAL' || company.type === 'MULTICOUNTRY';
    }

    return true;
  };

  const changeCountryPhoneMask = (): void => {
    const valueData =
      props.currentTab === SettingsTab.COMPANIES && !multiCountry && countryUser
        ? countryUser[0]
        : userCountryCode;

    setUserCountryCode(valueData);
  };

  useEffect(() => {
    setMultiCompany([Rol.BROKER, Rol.PRE_BROKER].includes(roleUser as Rol));
    if (roleUser === Rol.CUSTOMER) {
      if (firstRender) {
        if (currentUser.customerCode) {
          if (currentUser.customerCode.indexOf('|') > -1) {
            setCustomerTags(currentUser.customerCode.split('|'));
          } else if (currentUser.customerCode.length > 0) {
            setCustomerTags([currentUser.customerCode]);
          }
        }

        setFirstRender(false);
      }

      const isValid = () => {
        return customerTags.length > 0;
      };

      register(
        { name: 'customerCode', type: 'custom' },
        { validate: { isValid } },
      );
    } else {
      register(
        { name: 'customerCode', type: 'custom' },
        {
          validate: () => {
            return true;
          },
        },
      );
    }

    // Register rule if the phone number is valid
    register(
      { name: 'isValidPhoneNumber', type: 'custom' },
      {
        validate: () => {
          if(!use2FA) {
            return true;
          }

          const contactData = String(contactPhoneNumber).replaceAll('_', '');
          const formatPhoneCountry = phoneMasks[userCountryCode ?? ''];
          const formatIsValid =
            contactData.length === String(formatPhoneCountry).length;

          return formatIsValid;
        },
      },
    );

    register(
      { name: 'requirePhoneNumber', type: 'custom' },
      {
        validate: () => {
          if(!use2FA) {
            return true;
          }

          const contactData = String(contactPhoneNumber).replaceAll('_', '');

          return contactData.length >= 6;
        },
      },
    );
  }, [
    setFirstRender,
    currentUser.customerCode,
    customerTags,
    firstRender,
    register,
    roleUser,
    contactPhoneNumber,
    userCountryCode,
  ]);

  useEffect(() => {
    if (
      (multiCountry || multiCompany) &&
      (props.currentTab === SettingsTab.USERS ||
        props.currentTab === SettingsTab.COMPANIES)
    ) {
      let companyIds = props.listCompanies
        .filter(filterCompanies)
        .map((company: any) => String(company.id));

      if (!isNewUser) {
        if (companyUser) {
          companyIds = companyIds.filter((companyId: any) =>
            companyUser.includes(companyId),
          );
        }

        if (props.currentTab === SettingsTab.USERS) {
          const userCountryList =
            currentUser && currentUser.countryIdList
              ? currentUser.countryIdList.split(',')
              : [currentUser.codPais];
          const country =
            typeof countryUser === 'string' ? [countryUser] : countryUser;
          const newCountries = country.filter(
            (country: any) => !userCountryList.includes(country),
          );

          if (newCountries.length > 0) {
            const newCountryCompanies = props.listCompanies
              .filter((company: any) => newCountries.includes(company.code))
              .map((company: any) => String(company.id));

            companyIds.push(...newCountryCompanies);
          }
        }
      }

      setCompanyUser(companyIds);
    }
  }, [
    multiCountry,
    multiCompany,
    countryUser,
    props.currentTab,
    props.listCompanies,
    currentUser.countryIdList,
  ]);

  useEffect(() => {
    if (!multiCompany && !multiCountry && Array.isArray(companyUser)) {
      setCompanyUser(null);
    }
  }, [multiCompany]);

  useEffect(() => {
    changeCountryPhoneMask();
  }, [countryUser]);

  useEffect(() => {
    if (currentUser.cellphone) {
      setContactPhoneNumber(currentUser.cellphone);
    }

    if (currentUser.codPais) {
      setUserCountryCode(currentUser.codPais);
    }
  }, [currentUser]);

  return (
    <>
      <Loader isActive={loading} />
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container className="settingsForm__row" spacing={2}>
          <Grid key="grid-btn-3" item>
            <Button
              key="btnHideForm"
              name="btnHideForm"
              variant="outlined"
              disableElevation
              onClick={() => props.onShowForm(false)}
              className={clsx('settingsForm__toggle-button-3')}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid key="grid-btn-4" item>
            <Button
              key="btnAddOrUpdateUser"
              name="btnAddOrUpdateUser"
              variant="outlined"
              disableElevation
              type="submit"
              className={clsx('settingsForm__toggle-button-2')}
            >
              {props.labelForSubmit}
            </Button>
          </Grid>
        </Grid>

        {showMultiCountryButton && props.currentTab === SettingsTab.USERS && (
          <Grid container className="settingsForm__row" spacing={2}>
            <Grid key="grid-multicountry-check" item>
              <FormControlLabel
                key="check-multicountry"
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setMultiCountry(event.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="¿Es multipaís?"
              />
            </Grid>
          </Grid>
        )}

        <Grid container className="settingsForm__row" spacing={2}>
          <Grid key="grid-input-name" md={3} item>
            <TextField
              error={errors.username !== undefined}
              required
              key="username"
              className={clsx('settingsForm__input-auto')}
              name="username"
              label="Nombre"
              defaultValue={currentUser.name}
              variant="outlined"
              inputRef={register({ required: 'Nombre requerido' })}
            />
            {errors.username && (
              <ValidationLabel
                message={errors.username.message}
                isError={true}
              />
            )}
          </Grid>
          <Grid key="grid-input-lastname" md={3} item>
            <TextField
              error={errors.lastname !== undefined}
              required
              key="lastname"
              className={clsx('settingsForm__input-auto')}
              name="lastname"
              label="Apellido"
              defaultValue={currentUser.lastName}
              variant="outlined"
              inputRef={register({ required: 'Apellido requerido' })}
            />
            {errors.lastname && (
              <ValidationLabel
                message={errors.lastname.message}
                isError={true}
              />
            )}
          </Grid>
          <Grid key="grid-input-email" md={3} item>
            <TextField
              error={errors.email !== undefined}
              required
              key="email"
              className={clsx('settingsForm__input-auto')}
              name="email"
              label="Correo"
              defaultValue={currentUser.email}
              variant="outlined"
              inputRef={register({
                required: 'Correo requerido',
                pattern: patternEmail(),
              })}
            />
            {errors.email && (
              <ValidationLabel
                message={
                  errors.email.type === 'pattern'
                    ? 'Digite un correo válido'
                    : errors.email.message
                }
                isError={true}
              />
            )}
          </Grid>
          {useSelectedCountry && (
            <Grid key="grid-select-country" md={3} item>
              <FormControl
                variant="outlined"
                className={clsx(
                  'settingsForm__input-auto',
                  'settingsForm__select',
                )}
              >
                <InputLabel
                  shrink
                  style={{
                    backgroundColor: 'white',
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  País
                </InputLabel>
                <Select
                  onChange={(event: SelectChangeEvent<typeof countryUser>) => {
                    setCountryUser(
                      typeof event.target.value === 'string'
                        ? event.target.value.split(',')
                        : event.target.value,
                    );

                    if (
                      props.currentTab === SettingsTab.COMPANIES &&
                      multiCompany
                    ) {
                      setCompanyUser(null);
                    }
                  }}
                  value={
                    (multiCountry && typeof countryUser === 'string'
                      ? ([countryUser] as string[])
                      : countryUser) || []
                  }
                  multiple={multiCountry}
                  displayEmpty
                  name="selectCountry"
                  renderValue={
                    countryUser == null || countryUser.length === 0
                      ? () => '- Seleccione -'
                      : undefined
                  }
                >
                  {props.listCountries?.map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(countryUser == null || countryUser.length === 0) && (
                <ValidationLabel message="País requerido" isError={true} />
              )}
            </Grid>
          )}
        </Grid>
        <Grid container className="settingsForm__row" spacing={2}>
          <Grid key="grid-select-country-phone-code" md={1} item>
            <TextField
              fullWidth
              select
              label="Código País"
              disabled={
                props.currentTab === SettingsTab.COMPANIES && !multiCountry
              }
              value={userCountryCode}
              onChange={(event: any) => {
                setUserCountryCode(event.target.value);
              }}
            >
              <MenuItem value="CRC">CRC +506</MenuItem>
              <MenuItem value="PAN">PAN +507</MenuItem>
              <MenuItem value="COL">COL +57</MenuItem>
              <MenuItem value="PRI">PRI +1</MenuItem>
              <MenuItem value="MEX">MEX +52</MenuItem>
            </TextField>
            {(userCountryCode == null || userCountryCode.length === 0) && (
              <ValidationLabel message="Código es requerido" isError={true} />
            )}
          </Grid>
          <Grid key="grid-input-contact-number" md={2} item>
            <InputMask
              mask={phoneMasks[userCountryCode]}
              value={contactPhoneNumber}
              onChange={(event: any) => {
                if (userCountryCode != null && userCountryCode.length > 0) {
                  setContactPhoneNumber(event.target.value);
                }
              }}
            >
              {() => (
                <TextField
                  error={
                    errors.contactNumber !== undefined ||
                    errors.isValidPhoneNumber !== undefined ||
                    errors.requirePhoneNumber !== undefined
                  }
                  fullWidth
                  required
                  key="contactNumber"
                  id="contactNumber"
                  name="contactNumber"
                  label="# Contacto"
                  inputRef={(e) => {
                    inputRefCellPhoneField.current = e;
                  }}
                />
              )}
            </InputMask>
            {errors.contactNumber && (
              <ValidationLabel
                message={errors.contactNumber.message}
                isError={true}
              />
            )}
            {errors.isValidPhoneNumber && (
              <ValidationLabel
                message="Digite un # contacto válido"
                isError={true}
              />
            )}
            {errors.requirePhoneNumber && (
              <ValidationLabel
                message="# Contacto es requerido"
                isError={true}
              />
            )}
          </Grid>
          <Grid key="grid-select-2" md={3} item>
            <FormControl
              variant="outlined"
              className={clsx(
                'settingsForm__input-auto',
                'settingsForm__select',
              )}
            >
              <InputLabel
                shrink
                style={{
                  // Fixes select label drawn with input border
                  backgroundColor: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                Compañía
              </InputLabel>
              <Select
                onChange={(event: SelectChangeEvent<typeof companyUser>) => {
                  setCompanyUser(
                    typeof event.target.value === 'number'
                      ? String(event.target.value).split(',')
                      : event.target.value,
                  );
                }}
                defaultValue={String(currentUser.companyId)}
                name="selectCompany-2"
                displayEmpty
                value={
                  ((multiCompany || multiCountry) &&
                  typeof companyUser === 'string'
                    ? ([companyUser] as string[])
                    : companyUser) || []
                }
                renderValue={
                  companyUser == null || (companyUser as string[]).length === 0
                    ? () => '- Seleccione -'
                    : undefined
                }
                multiple={multiCountry || multiCompany}
              >
                {props.listCompanies
                  .filter(filterCompanies)
                  .map((option: any) => (
                    <MenuItem key={option.id} value={String(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {(companyUser == null ||
              ((companyUser as unknown) as number) < 1 ||
              (companyUser as string[]).length === 0) && (
              <ValidationLabel message="Compañía requerida" isError={true} />
            )}
          </Grid>
          <Grid key="grid-select-5" md={3} item>
            <FormControl
              variant="outlined"
              className={clsx(
                'settingsForm__input-auto',
                'settingsForm__select',
              )}
            >
              <InputLabel
                style={{
                  // Fixes select label drawn with input border
                  backgroundColor: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                Rol
              </InputLabel>
              <Select
                onChange={(event: any) => {
                  setRoleUser(+event.target.value);
                }}
                defaultValue={currentUser.rolId}
                name="selectCompany-3"
              >
                <MenuItem key="0" value="0">
                  - Seleccione -
                </MenuItem>
                {filteredRoleList.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {roleUser != null && roleUser < 1 && (
              <ValidationLabel message="Rol requerido" isError={true} />
            )}
          </Grid>
        </Grid>
        <Grid container className="settingsForm__row" spacing={2}>
          {(roleUser === Rol.BROKER || roleUser === Rol.PRE_BROKER) && (
            <Grid key="grid-select-4" md={3} item>
              <FormControl
                variant="outlined"
                className={clsx(
                  'settingsForm__input-auto',
                  'settingsForm__select',
                )}
              >
                <InputLabel
                  style={{
                    // Fixes select label drawn with input border
                    backgroundColor: 'white',
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  Código de Corredor
                </InputLabel>
                <Select
                  onChange={(event: any) => {
                    setRunnerCode(event.target.value);
                  }}
                  defaultValue={currentUser.runnerCode}
                  name="selectRunner-1"
                >
                  <MenuItem key="0" value="0">
                    - Seleccione -
                  </MenuItem>
                  {props.listRunnerOwners &&
                    filteredBrokerList.map((option: any) => (
                      <MenuItem
                        key={option.runnerCode}
                        value={option.runnerCode}
                      >
                        {option.runnerName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {(runnerCode === undefined ||
                ((runnerCode as unknown) as number) < 1 ||
                runnerCode.length < 1) && (
                <ValidationLabel message="Código es requerido" isError={true} />
              )}
            </Grid>
          )}
          {roleUser === Rol.INSPECTOR && (
            <Grid key="grid-input-vendor" md={3} item>
              <TextField
                error={errors.vendorID !== undefined}
                required
                key="vendorID"
                className={clsx('settingsForm__input-auto')}
                name="vendorID"
                label="Código de Proveedor"
                defaultValue={currentUser.vendorID}
                variant="outlined"
                inputRef={register({
                  required: 'Código es requerido',
                })}
              />
              {errors.vendorID && (
                <ValidationLabel
                  message={errors.vendorID.message}
                  isError={true}
                />
              )}
            </Grid>
          )}
          {roleUser === Rol.CUSTOMER && (
            <Grid key="grid-input-customer" md={3} item>
              <MuiChipsInput
                value={customerTags}
                onChange={handleTagChange}
                label="Código de Cliente"
                placeholder="Escribe y presiona enter"
                hideClearAll={true}
                className={clsx('settingsForm__input-auto')}
              />
              {errors.customerCode && (
                <ValidationLabel message="Código es requerido" isError={true} />
              )}
            </Grid>
          )}
          <Grid key="grid-input-2fa" item>
            <FormControlLabel
              key="check-change-2fa"
              control={
                <Checkbox
                  defaultChecked={currentUser.use2FA}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUse2FA(event.target.checked);
                    inputRefCellPhoneField?.current?.focus();
                  }}
                  color="primary"
                />
              }
              label="Usar 2FA"
            />
          </Grid>
          <Grid key="grid-input-sso" item>
            <FormControlLabel
              key="check-change-sso"
              control={
                <Checkbox
                  defaultChecked={currentUser.useSSO}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUseSSO(event.target.checked);
                  }}
                  color="primary"
                />
              }
              label="Usar SSO"
            />
          </Grid>
          {!isNewUser && (
            <>
              <Grid key="grid-input-check" item>
                <FormControlLabel
                  key="check-change-password"
                  control={
                    <Checkbox
                      checked={changePassword}
                      onChange={() => {
                        setNewPassword('');
                        requestChangePW();
                      }}
                      color="primary"
                    />
                  }
                  label="Solicitar Cambiar Contraseña"
                />
              </Grid>
              <Grid key="grid-input-check2" item>
                <FormControlLabel
                  key="check-change-active"
                  control={
                    <Checkbox
                      defaultChecked={currentUser.active}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setActiveUser(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="Activo"
                />
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {/* Modals */}
      <Dialog
        open={userState.showPasswdReqAlert}
        onClose={closeAlertRequest}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Actualización de Contraseña
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!userState.isErrorPasswdReqAlert && (
              <p>
                Se enviará un correo con un <b>código de verificación</b> para
                que el usuario pueda retomar el proceso de actualizar su
                contraseña.
              </p>
            )}
            {userState.isErrorPasswdReqAlert && (
              <p>
                El servicio de usuarios tiene incovenientes para enviar la
                solicitud, por favor intente en un momento.
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeAlertRequest}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={userState.showCreateUsrAlert}
        onClose={closeAlertNewUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Mantenimiento de Usuarios
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!userState.isErrorCreateUsrAlert &&
              currentUser &&
              currentUser.userCompanyID &&
              currentUser.userCompanyID > 0 && (
                <p>Usuario actualizado satisfactoriamente.</p>
              )}
            {!userState.isErrorCreateUsrAlert && !currentUser.userCompanyID && (
              <p>
                Usuario creado satisfactoriamente, se enviará un correo con la
                información para que el usuario proceda con el acceso a la
                plataforma.
              </p>
            )}
            {userState.isErrorCreateUsrAlert && (
              <>
                {userState.msgCreateUsrAlert.length > 0 && (
                  <p>{userState.msgCreateUsrAlert}</p>
                )}
                {userState.msgCreateUsrAlert.length === 0 && (
                  <p>
                    Se ha encontrado un incoveniente para crear el usuario, por
                    favor intente en un momento.
                  </p>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeAlertNewUser}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserForm;
