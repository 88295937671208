import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import { Search, Cancel as CancelIcon, Menu } from '@mui/icons-material';
import { FormControl, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCurrentCognitoUser, IUserContext, requestSignOut, UserContext } from 'context/user';
import { BrandsId, CmtCountries, CountryList, Rol } from 'types/common';
import { IAppFormType } from 'types/user';
import PATH_ROUTES from 'static/constants/path-routes';
import SICLogo from 'assets/images/SIC-Logo.svg';
import Logo from 'assets/images/Logo.svg';
import { BreakpointsFlags } from 'shared/hooks/useDevice';
import HeaderContent from './HeaderContent';
import { CountryCode } from 'types/common';
import Loader from 'components/common/loader/Loader';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import UserProfile from 'components/common/userProfile';
import styles from './override-mui-styles';
import './styles.scss';

interface Props {
  isDrawerOpen: boolean;
  searchText: string;
  searchType: string;
  breakpoints: BreakpointsFlags;
  onpenDrawer: (value: boolean) => void;
  onChangeRecord: (type: string, value: string) => void;
  onClickSearch: () => void;
  onClear: () => void;
  onCountryChange: (countryCode: string) => void;
}

interface FilterType {
  filterId: string;
  filterName: string;
}

const filterTypePermission = (type: IAppFormType): FilterType[] => {
  const userCompanyId = localStorage.getItem('companyId') ?? '-1';
  const userRolId = localStorage.getItem('rolId');
  let filterOptions: FilterType[] = [];

  if (type === IAppFormType.INSPECTION) {
    filterOptions = [
      { filterId: 'PO_NUMBER', filterName: 'PO' },
      { filterId: 'INSURED_PLATE', filterName: 'Placa Asegurado' },
      { filterId: 'THIRD_PLATE', filterName: 'Placa Tercero' },
      { filterId: 'VIN', filterName: 'VIN de Vehículo' },
      { filterId: 'POLICY', filterName: 'Póliza' },
    ];

    //CMT
    if (localStorage.getItem('countryCode') === CountryCode.PRI) {
      //Items to Delete
      filterOptions = filterOptions.filter(
        (option) => option.filterId !== 'INSURED_PLATE',
      );
      filterOptions = filterOptions.filter(
        (option) => option.filterId !== 'THIRD_PLATE',
      );

      //Items to Add
      const indexArray = filterOptions.findIndex(
        (object) => object.filterId === 'PO_NUMBER',
      );
      filterOptions.splice(indexArray + 1, 0, {
        filterId: 'RECLAMATION',
        filterName: 'Reclamación',
      });
      filterOptions.splice(indexArray + 2, 0, {
        filterId: 'CLAIM_NUMBER',
        filterName: 'Caso',
      });
      filterOptions.push({ filterId: 'CONTACT_PHONE', filterName: 'Teléfono' });
      filterOptions.push({ filterId: 'GENERAL_PLATE', filterName: 'Placa' });
      filterOptions.push({
        filterId: 'FULL_NAME',
        filterName: 'Nombre y Apellido',
      });
    }

    // Only Admin Intern
    if (Rol.ADMIN === Number(userRolId) || Rol.ADJUSTER === Number(userRolId)) {
      filterOptions.push({ filterId: 'SIC_ID', filterName: 'SIC ID' });
    }

    // Qualitas
    if (
      Rol.ADMIN === Number(userRolId) ||
      Rol.ADJUSTER === Number(userRolId) ||
      (Rol.INSURER === Number(userRolId) &&
        BrandsId.QUALITAS === Number(userCompanyId))
    ) {
      filterOptions.push({ filterId: 'AR', filterName: '# Alta Reporte' });
      filterOptions.push({ filterId: 'AS', filterName: '# Alta Siniestro' });
    } else if (Rol.INSPECTOR === Number(userRolId)) {
      filterOptions.push({ filterId: 'AR', filterName: '# Alta Siniestro' });
    }

    // Assa
    if (
      Rol.ADMIN === Number(userRolId) ||
      Rol.ADJUSTER === Number(userRolId) ||
      Rol.INSPECTOR === Number(userRolId) ||
      (Rol.INSURER === Number(userRolId) &&
        BrandsId.ASSA === Number(userCompanyId))
    ) {
      filterOptions.push({ filterId: 'CLAIM', filterName: '# Reclamo' });
    }
  } else if (type === IAppFormType.PRE_INSPECTION) {
    filterOptions = [
      { filterId: 'PRE_NUMBER', filterName: 'Expediente' },
      { filterId: 'QUOTE_NUMBER', filterName: '# Cotización' },
      { filterId: 'INSURED_PLATE', filterName: 'Placa' },
      { filterId: 'VIN', filterName: 'VIN de Vehículo' },
      { filterId: 'CHASIS', filterName: 'Motor de Vehículo' },
      { filterId: 'NAME', filterName: 'Por Nombre' },
      { filterId: 'PHONE_NUMBER', filterName: 'Por Teléfono' },
      { filterId: 'EMAIL', filterName: 'Por Correo' },
    ];
  }

  return filterOptions;
};

const TopBar = (props: Props): JSX.Element => {
  const classes = styles();
  const currentHistory = useHistory();
  const {
    state: { currentUser, appFormType },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const { dispatch: inspectDispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const [selectedPlaceHolder, setSelectedPlaceHolder] = useState('Expediente');
  const [currentFilterType, setCurrentFilterType] = useState(
    filterTypePermission(appFormType).filter(
      (f: FilterType) => f.filterId === props.searchType,
    )[0] ?? filterTypePermission(appFormType)[0],
  );
  const [countryUser, setCountryUser] = useState<string>('');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const { isDesktop, isMobile } = props.breakpoints;

  const countryCode = localStorage.getItem('countryCode');
  const showMultiCountryControl =
    currentUser.isMultiCountry &&
    [Rol.ADMIN, Rol.ADJUSTER, Rol.AUDIT].includes(currentUser?.rolId ?? 0);

  const filterTypeOnChange = (event: any) => {
    if (event.target) {
      props.onChangeRecord(event.target.value, props.searchText);
      const currentFilter = filterTypePermission(appFormType).filter(
        (f: FilterType) => f.filterId === event.target.value,
      )[0];

      setCurrentFilterType(currentFilter);
      setSelectedPlaceHolder(currentFilter.filterName);
      fieldValidations(props.searchText);
    }
  };

  function fieldValidations(text: string): boolean {
    setErrorText('');
    if (currentFilterType.filterId === 'CONTACT_PHONE' && text.length < 6) {
      setErrorText('Ingresar por lo menos 6 caracteres');

      return false;
    } else if (
      currentFilterType.filterId === 'GENERAL_PLATE' &&
      text.length < 6
    ) {
      setErrorText('Ingresar por lo menos 6 caracteres');

      return false;
    } else if (currentFilterType.filterId === 'FULL_NAME' && text.length < 3) {
      setErrorText('Ingresar por lo menos 3 caracteres');

      return false;
    } else {
      setErrorText('');

      return true;
    }
  }

  const filter = (
    <div className="topBar__searchInput">
      <FormControl variant="filled" className="topBar__filterType">
        <Select
          labelId="select-outlined-label"
          id="filter-select-outlined"
          value={currentFilterType.filterId}
          onChange={filterTypeOnChange}
          label="Filtro"
          IconComponent={ExpandMoreIcon}
        >
          {filterTypePermission(appFormType).map((ft: FilterType) => {
            return (
              <MenuItem key={ft.filterId} value={ft.filterId}>
                {ft.filterName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div>
        <InputBase
          className="filterText"
          placeholder={'Buscar ' + selectedPlaceHolder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={props.searchText}
          onChange={(event) => {
            props.onChangeRecord(
              currentFilterType.filterId,
              event.target.value,
            );
            fieldValidations(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              if (fieldValidations(props.searchText)) {
                props.onClickSearch();
              }
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="clear input" onClick={props.onClear}>
                {props.searchText && <CancelIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
        <br></br>
        {errorText ? (
          <input
            type="text"
            name="errorText"
            className={`${classes.inputRoot} ${classes.inputInput}`}
            style={{ position: 'absolute', color: 'red' }}
            value={errorText}
            readOnly
          ></input>
        ) : null}
      </div>
      <IconButton
        className={clsx(classes.iconSearch)}
        onClick={() => {
          if (fieldValidations(props.searchText)) {
            props.onClickSearch();
          }
        }}
      >
        <Search />
      </IconButton>
    </div>
  );

  const closeUserSession = (): void => {
    setLoading(true);
    (async (): Promise<void> => {
      localStorage.clear();
      await requestSignOut();

      userDispatch({ type: ActionType.INIT_STATE });
      inspectDispatch({ type: ActionType.INIT_STATE });
      currentHistory.push('/');
    })();
  };

  const redirectToUserProfile = (): void => {
    currentHistory.push(PATH_ROUTES.USERS_PROFILE);
  };

  useEffect(() => {
    if (filterTypePermission(appFormType).length > 0) {
      const currentValue =
        filterTypePermission(appFormType).filter(
          (f: FilterType) => f.filterId === props.searchType,
        )[0] ?? filterTypePermission(appFormType)[0];
      setCurrentFilterType(currentValue);
      setSelectedPlaceHolder(currentValue.filterName);
    }
  }, [appFormType, props.searchType]);

  useEffect(() => {
    setCountryUser(currentUser.currentCountryCode || '');
  }, [currentUser]);

  return (
    <>
      <Loader isActive={loading} />

      <div
        className={clsx('topBar', { 'topBar--smaller': props.isDrawerOpen })}
      >
        <div className="topBar-header">
          {!isDesktop && (
            <img
              src={
                CmtCountries.includes(countryCode as CountryCode)
                  ? Logo
                  : SICLogo
              }
              className="drawer__sicLogo--large"
              alt="SICLogo"
            />
          )}
          <HeaderContent />
          {!isMobile && filter}
          {showMultiCountryControl && (
            <FormControl sx={{ minWidth: 175 }}>
              <Select
                onChange={(event) => {
                  setCountryUser(event.target.value);
                  props.onCountryChange(event.target.value);
                }}
                value={countryUser}
                name="selectCountry"
              >
                {CountryList.filter((e) =>
                  currentUser.countryIdList?.split(',').includes(e.code),
                ).map((option: any) => (
                  <MenuItem key={option.code} value={option.code}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={option.icon}
                        alt={`${option.name} flag`}
                        style={{ marginRight: '8px' }}
                      />
                      <span>{option.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <div className="topBar__options">
            {isDesktop && (
              <UserProfile
                name={currentUser.name}
                onCloseSession={closeUserSession}
                onGoToProfile={redirectToUserProfile}
              />
            )}
            {!isDesktop && (
              <IconButton
                onClick={() => props.onpenDrawer(!props.isDrawerOpen)}
              >
                <Menu />
              </IconButton>
            )}
          </div>
        </div>
        {isMobile && <div className="topBar-filters">{filter}</div>}
      </div>
    </>
  );
};

export default TopBar;
